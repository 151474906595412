import React from 'react'
import { PHONE_NUMBER_STATE } from '@apps/redux/features/PhoneNumberSlice'
import { ACCOUNT_SETUP_STATE } from '@apps/redux/features/AccountSetupSlice'
import { APPLICATION_STATE } from '@apps/contexts/ApplicationContext'
import { PLANS_STATE } from '@apps/redux/features/PlansSlice'
import { PAYMENT_STATE } from '@apps/redux/features/PayAndReviewSlice'
import { PHONE_NUMBER_OPTIONS, PAYMENT_OPTIONS, PORT_ORDER_AUTH_TYPE } from '@apps/utils/constants'
import Sites from '@/sites'
import { convertDollarsToCents } from '@apps/flows/activation/services/accountHelper'

const getNewNumber = ({ phoneNumber, application }) => {
  const {
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER]: numSelectedInfo,
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]: numProvinceGroupId,
    [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]: selectedPhoneNumberOption
  } = phoneNumber

  const { [APPLICATION_STATE.PROVINCE_GROUPS]: provinceGroups = [] } = application

  if (selectedPhoneNumberOption === PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER) {
    return {
    phoneNumberId: '',
    provinceGroup: ''
    }
  }

  const { groupName = '' } =
    provinceGroups.find((group) => {
      return group.value === numProvinceGroupId
    }) || {}

  return {
    phoneNumberId: numSelectedInfo?.id ? numSelectedInfo.id : '',
    provinceGroup: groupName
  }
}

const getCommon = ({ common, accountSetup, application, phoneNumber, plans, payment }) => {
  const {
    [ACCOUNT_SETUP_STATE.PROVINCE]: province
  } = accountSetup

  const { [APPLICATION_STATE.PROVINCES]: provinces } = application

  const { currentProvinceId } = common

  const {
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER]: streetNumber = null,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME]: streetName = null,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME]: city = null,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE
      .ALTERNATE_CONTACT_NUMBER]: wirelineAlternateContactNumber = null,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER]: wirelessAlternateContactNumber
  } = phoneNumber

  const { regionId } =
    provinces.find((prov) => {
      return prov.value === province || currentProvinceId
    }) || {}

  const altPhoneNumber =
    wirelessAlternateContactNumber || wirelineAlternateContactNumber || '0000000000'

  return {
    city: city || 'Update required',
    address: streetNumber && streetName ? `${streetNumber} ${streetName}`.trim() : 'Update required',
    postalCode: payment?.postalCode || 'Update',
    provinceRegionId: regionId,
    altPhoneNumber: altPhoneNumber.replaceAll(' ', '')
  }
}

const getOutlet = ({ application }) => {
  const { [APPLICATION_STATE.IDENTITY]: identity } = application
  const { channelOrgCode, outletId, salesRepId, outletProvince } = identity

  return {
    bannerNum: channelOrgCode || '',
    outletId: outletId || '',
    salesRepId: salesRepId || '',
    outletProvince: outletProvince || ''
  }
}

const getPayment = (state, paymentSalt) => {
  const { payment, plans, simSelectionPage } = state
  const {
    [PAYMENT_STATE.SELECTED_PAYMENT_OPTION]: selectedPaymentOption,
    [PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS]: addedVouchers,
    [PAYMENT_STATE.SUMMARY.AMOUNT_TAX]: taxAmount,
    [PAYMENT_STATE.SUMMARY.TAX_INFO]: taxInfo,
    [PAYMENT_STATE.SUMMARY.AMOUNT_OWING]: totalAmount,
    [PAYMENT_STATE.AUTO_TOP_UPS]: autoPay
  } = payment

  const voucherAutoPay = selectedPaymentOption === PAYMENT_OPTIONS.VOUCHER || (selectedPaymentOption === PAYMENT_OPTIONS.VOUCHER && autoPay)

  const voucherCodes =
    selectedPaymentOption === PAYMENT_OPTIONS.VOUCHER
      ? addedVouchers
          .map((item) => {
            return item.id
          })
          .join(',')
      : null
  const creditDetails =
    selectedPaymentOption === PAYMENT_OPTIONS.CREDIT_CARD || voucherAutoPay
      ? {
          maskedCardNumber: paymentSalt && paymentSalt?.CARD_NUMBER || '',
          expiry: paymentSalt && paymentSalt?.STORAGE_TOKEN_EXPIRY || '',
          paymentToken: paymentSalt && paymentSalt?.STORAGE_TOKEN_ID || '',
          promoCode:  getPromoCode(state) || '',
          promoCodeId:  getPromoCodeIdentifier(state) || '',
          rafCode:  getReferral(state) || '',
          paymentPostalCode: payment?.postalCode || '',
          billingProvinceCode:  getSimProvince(state)
        }
      : null
  const amountInfo = selectedPaymentOption === PAYMENT_OPTIONS.CREDIT_CARD || voucherAutoPay
      ? {
          planAmount: (plans?.plan?.value?.toFixed(2)).toString() || '0.00',
          simAmount: (simSelectionPage?.selectSimType?.cost?.toFixed(2)).toString() || '0.00',
          promoDiscount: (getTotalPromo(state)?.toFixed(2)).toString(),
          simDeliveryAmount:'0.00',
          subTotal: (Number(totalAmount) - Number(taxAmount)).toFixed(2),
          taxAmount,
          taxInfo,
          totalPaid: convertDollarsToCents(totalAmount)
        }
      : null

  return {
    isPayByVoucher: selectedPaymentOption === PAYMENT_OPTIONS.VOUCHER,
    isAutopay: selectedPaymentOption === PAYMENT_OPTIONS.CREDIT_CARD || autoPay,
    voucherCodes,
    creditDetails,
    amountInfo
  }
}

const getReferral = ({ payment }) => {
  const {
    [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE]: raf = null,
    [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_IS_VALID]: rafIsValid
  } = payment

  return rafIsValid ? raf : null
}

const getPromoCodeIdentifier = ({ payment, plans }) => {
  const { 
    [PAYMENT_STATE.PROMO.PROMOTION_CODE_INFO]: promoCodeInfo = {},
    [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_INFO]: multiPromotionCodeInfo
 } = payment

  if(multiPromotionCodeInfo.length >= 1) {
    const promoCodeIdsArray = multiPromotionCodeInfo.map(promo => promo.id)
    const promoCodeIds = promoCodeIdsArray.join(';')
    return promoCodeIds
  }
  const { id: manualPromoId = null } = promoCodeInfo || {}

  const { [PLANS_STATE.PROMO]: planPromo = {} } = plans
  const { id: plansPromoId = null } = planPromo || {}

  //  only apply 1 promo
  return manualPromoId || plansPromoId
}

const getPromoCode = ({ payment }) => {
  const { 
    [PAYMENT_STATE.PROMO.MULTI_PROMO_CODES]: multiPromoCodes
 } = payment
  const multiPromotionCodes = multiPromoCodes?.join(';')

  return multiPromotionCodes
}

export const getCardValidationParams = ({ payment }) => {
  const {
    [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER]: cardNumber,
    [PAYMENT_STATE.CC_INFO.EXPIRY_DATE]: expiry,
    [PAYMENT_STATE.CC_INFO.SECURITY_CODE]: cvv,
    [PAYMENT_STATE.CC_INFO.POSTAL_CODE]: postalCode
  } = payment

  return {
    timestamp: Date.now(),
    cardNumber: cardNumber.replaceAll(' ', ''),
    expiry: expiry.replaceAll('/', ''),
    cvv,
    postalCode: postalCode.replaceAll(' ', '')
  }
}

const getTotalPromo = ({ plans, payment }) => {
  if (payment?.multiPromotionCodeInfo && payment?.multiPromotionCodeInfo?.length > 0) {
    const { [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_INFO]: multiPromotionCodeInfo } = payment
    return getTotalMultiPromoDiscounts(multiPromotionCodeInfo)
  }

  const { [PAYMENT_STATE.PROMO.PROMOTION_CODE_INFO]: promoCodeInfo = {} } = payment
  const { bundles: manualPromoBundle = [] } = promoCodeInfo || {}

  const { [PLANS_STATE.PROMO]: promo = {} } = plans
  const { bundles: plansPromoBundle = [] } = promo || {}

  return getTotalPromoDiscounts([...manualPromoBundle, ...plansPromoBundle])
}

const getTotalPromoDiscounts = (bundles = []) => {
  return bundles.reduce((a, b) => Number(a.fee) || 0 + Number(b.fee) || 0, 0)
}

const getTotalMultiPromoDiscounts = (multiPromotionCodeInfo) => {
  let result = 0
  multiPromotionCodeInfo.forEach(promoBundle => {
    const { bundles = [] } = promoBundle || {}
    bundles.forEach((bundle) => {
      const { fee = 0 } = bundle
      result += Number(fee)
    })
  })
  return result
}

export const getPromotionAndValidationCodes = (multiPromoCodes) => {
  if(multiPromoCodes.length === 0) return ''
  const promotionAndValidationCodes = multiPromoCodes.join(';')
  return promotionAndValidationCodes
}

const getSimProvince = ({ application, phoneNumber, common }) => {
  const { [APPLICATION_STATE.PROVINCES]: mappedProvinceList } = application
  const {
    [PHONE_NUMBER_STATE.PROVINCE_ID]: phoneNumberProvinceID,
    [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]: selectedPhoneNumberOption
  } = phoneNumber
  const { currentProvinceId } = common
  let provID = phoneNumberProvinceID
  if (selectedPhoneNumberOption !== PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER) {
    provID = currentProvinceId
  }
  return mappedProvinceList.find((pr) => pr.value === provID)?.shortName
}

const getEmailAddress = ({common}) => {
  const { email } = common?.user
  return email
}

export const getActivateAllReqPayload = (state, paymentSalt) => {
  const response = {
    emailAddress: getEmailAddress(state),
    newNumber: getNewNumber(state),
    common: getCommon(state),
    payment: getPayment(state, paymentSalt),
    optionIds: null,
    simProvince: getSimProvince(state)
  }

  const { application } = state
  const { [APPLICATION_STATE.SITE]: site } = application
  if (site === Sites.dealer) {
    Object.assign(response, { outlet: getOutlet(state) })
  } else {
    Object.assign(response, {
      outlet: {
        bannerNum: '',
        outletId: '',
        salesRepId: '',
        outletProvince: ''
      }
    })
  }

  return response
}
