import React, { useContext, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'

import ContentService from '@services/ContentService'
import Button from '@apps/components/Button'

import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import DealerActivationPaths from '@sites/dealer/activation/DealerActivationPaths'
import Heading2 from '@apps/components/Typography/Heading2'
import ActivateSIMSection from '@apps/flows/activation/sections/ActivateSIMSection'
import BodyBold from '@apps/components/Typography/BodyBold'
import {
  APPLICATION_STATE,
  ApplicationContext,
  getProvince,
  ModifyApplicationContext,
  setLandingLogicLoader
} from '@apps/contexts/ApplicationContext'
import { setESimNumber } from '@apps/redux/features/SimSelectionSlice'
import { PAYMENT_STATE, updatePaymentStates } from '@apps/redux/features/PayAndReviewSlice'
import { setPageAnalytics } from '@apps/utils/Analytics'
import { PAYMENT_OPTIONS, VALID_AVS_CODES, PHONE_NUMBER_OPTIONS, CARD_VALIDATION_ERRORS } from '@apps/utils/constants'
import { getActivateAllReqPayload } from '@apps/utils/requestPayloadHelper'
import Popup from '@apps/components/Popup'
import {
  ACCOUNT_SETUP_STATE,
  updateAccountSetupState
} from '@apps/redux/features/AccountSetupSlice'
import { PHONE_NUMBER_STATE, updatePhoneNumberState } from '@apps/redux/features/PhoneNumberSlice'
import { PLANS_STATE } from '@apps/redux/features/PlansSlice'
import {
  updateActivateAllResponse,
  setQuoteInfo,
  setActivationQrCode,
  setIsSimValidFlag
} from '@apps/redux/features/CommonSlice'
import Heading1 from '@apps/components/Typography/Heading1'
import Loader from '@apps/components/Loader'
import { ContentContext } from '@apps/contexts/ContentContext'
import BodyError from '@apps/components/Typography/BodyError'
import Sites from '@sites/index'
import useSite from '@engine/hooks/useSite'
import { getById, getImgByTitle } from '@apps/utils/contentful'
import PlanUnavailablePopup from '@apps/components/PlanUnavailablePopup'
import PaymentOption from './PaymentOption'
import PromoCode from './PromoCode'
import useLocale from '../../../_engine/hooks/useLocale'

const PaymentPage = ({ onComplete }) => {
  const dispatch = useDispatch()
  const {
    content: { pageContent },
    user: { selectedLanguage }
  } = useSelector((state) => state.common)

  const appState = useContext(ApplicationContext)
  const setAppState = useContext(ModifyApplicationContext)

  const { lang } = useLocale()
  const paymentStateUpdate = useSelector((state) => state.payAndReviewPage)

  const consumerActivationPaths = new ConsumerActivationPaths()
  const dealerActivationPaths = new DealerActivationPaths()
  const { dynamicContent } = useContext(ContentContext)

  const { userType } = useSelector((state) => state.common)
  const paymentState = useSelector((state) => state.payAndReviewPage)
  const { quote } = useSelector((state) => state.common)
  const { simNumber } = useSelector((state) => state.simSelectionPage)

  const content = useMemo(() => {
    const commonElements = getById(pageContent, 'commonElements')[selectedLanguage]
    return {
      exclamation: getImgByTitle(commonElements, 'exclamation')?.url,
      chevronBack: getImgByTitle(commonElements, 'chevronBack')?.url
    }
  }, [pageContent, selectedLanguage])

  const [showActivatingPopup, setShowActivatingPopup] = useState(false)
  const [isProcessingPayment, setIsProcessingPayment] = useState(false)
  const [isActivateButtonDisabled, setIActivateButtonDisabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isActivateAllProcessing, setIsActivateAllProcessing] = useState(true)
  const [isFirstLoaderComplete, setIsFirstLoaderComplete] = useState(false)

  const [buttonIsLoading, setButtonIsLoading] = useState(false)

  const contextState = {
    application: useContext(ApplicationContext),
    accountSetup: useSelector((state) => state.accountSetupPage),
    simSelectionPage: useSelector((state) => state.simSelectionPage),
    phoneNumber: useSelector((state) => state.phoneNumberPage),
    plans: useSelector((state) => state.plansPage),
    payment: useSelector((state) => state.payAndReviewPage),
    common: useSelector((state) => state.common)
  }

  const updatePaymentStore = (payload) => {
    dispatch(updatePaymentStates(payload))
  }

  const site = useSite()

  const {
    [APPLICATION_STATE.SERVICES]: brandServices,
    [APPLICATION_STATE.PROVINCE_GROUPS]: provinceGroups = [],
    [APPLICATION_STATE.CURRENT_REGION]: currentRegion
  } = contextState.application

  const {
    [PHONE_NUMBER_STATE.PROVINCE_ID]: provinceId,
    [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]: selectedPhoneNumberOption
  } = contextState.phoneNumber

  const { activateAll, getCity, isSimValid, retrieveQuoteByEmail } = brandServices

  const {
    [PAYMENT_STATE.SELECTED_PAYMENT_OPTION]: selectedPaymentOption,
    [PAYMENT_STATE.AUTO_TOP_UPS]: autoPay,
    [PAYMENT_STATE.CC_INFO.MASKED]: masked,
    [PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS]: addedVouchers
  } = contextState.payment

  const { [PLANS_STATE.PRE_SELECTED_PLAN]: preSelectedPlan } = contextState.plans
  const usePreSelectedPlanOptions = preSelectedPlan && userType === Sites.consumer

  const {
    [PAYMENT_STATE.RAF.FRIEND_REFERRAL_SETTINGS]: friendReferralSettings,
    [PAYMENT_STATE.PAYMENT_GATEWAY]: paymentGateway
  } = paymentState

  const history = useHistory()
  if (dynamicContent && dynamicContent.accountSetupPage.featureFlags.showSplashing) {
    history.push(consumerActivationPaths.Splash)
  }

  const currProvince = useMemo(() => {
    if (selectedPhoneNumberOption === PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER) {
      const { shortName } = getProvince(provinceId)
      return shortName
    }
    return currentRegion
  }, [provinceId, currentRegion, selectedPhoneNumberOption])

  const activateSimCard = async (paymentResult = '') => {
    setButtonIsLoading(false)
    let parsedPaymentResult = null
    const voucherAutoPaySelected = selectedPaymentOption === PAYMENT_OPTIONS.VOUCHER && autoPay

    if (selectedPaymentOption === PAYMENT_OPTIONS.CREDIT_CARD || voucherAutoPaySelected) {
      let isValidPaymentResult
      if (paymentGateway === 'EPS') {
        parsedPaymentResult = paymentResult
        isValidPaymentResult = paymentResult
      } else {
        parsedPaymentResult = Object.fromEntries(
          paymentResult?.split('\n')?.map((pair) => pair?.split('='))
        )
        isValidPaymentResult =
          parsedPaymentResult &&
          parsedPaymentResult.APPROVED === 'true' &&
          VALID_AVS_CODES.includes(parsedPaymentResult.AVS_RESPONSE_CODE) &&
          parsedPaymentResult.STORAGE_TOKEN_ID
      }

      if (isValidPaymentResult) {
        updatePaymentStore({
          [PAYMENT_STATE.CC_INFO.CC_API_VALIDATION_ERROR]: '',
          [PAYMENT_STATE.CC_INFO.MASKED]: parsedPaymentResult.CARD_NUMBER
        })
      } else {
        updatePaymentStore({
          [PAYMENT_STATE.CC_INFO.CC_API_VALIDATION_ERROR]: CARD_VALIDATION_ERRORS.FAILED
        })
        setButtonIsLoading(false)
        setIsProcessingPayment(false)
        setShowActivatingPopup(false)
        setIActivateButtonDisabled(false)
        return
      }
    }

    setIsProcessingPayment(false)
    setShowActivatingPopup(false)
    setIActivateButtonDisabled(true)

    const simNum = simNumber?.replace(/[-\s]/g, '') || quote?.quoteInfo?.SimInformation?.simNumber
    const isSimNumValid = simNum ? await isSimValid(simNum) : ''

    if (simNum && (!isSimNumValid || isSimNumValid === null)) {
      dispatch(setIsSimValidFlag(isSimNumValid))
      history.push(consumerActivationPaths.SimSelection)
      return
    }

    const requestPayload = getActivateAllReqPayload(contextState, parsedPaymentResult)
    handleFirstLoaderComplete()
    const response = await activateAll(requestPayload)
    setShowActivatingPopup(false)
    if (response) {
      if (response.error && response.error.message && !response?.data) {
        const res = await retrieveQuoteByEmail({ email: requestPayload?.emailAddress })
        if (res?.data?.quoteId) {
          dispatch(setQuoteInfo(res.data))
        }

        setIsActivateAllProcessing(false)

        dispatch(updateActivateAllResponse({ responseError: response?.error }))

        // error loader and hide loader
        setErrorMessage(response.error)

        //  error loader
        // 1 . generic errro and  retrieve button
        // 2. payment and label ( submitquote success )
        // 3. after success submitquote api  any api error no label - customer support
        if (site === Sites.consumer) {
          history.push(consumerActivationPaths.ProcessFailure)
        } else {
          history.push(dealerActivationPaths.ProcessFailure)
        }

        return
      }
      if (response.data) {
        dispatch(setESimNumber(response?.data?.eSimNumber))
        dispatch(
          updateAccountSetupState({
            [ACCOUNT_SETUP_STATE.BAN]: response?.data?.ban
          })
        )

        dispatch(setActivationQrCode(response?.data?.activationQRCode))

        dispatch(updateActivateAllResponse({ postPaymentError: response?.error }))
        const { pathname } = window.location
        if (site === Sites.consumer) {
          history.push(consumerActivationPaths.TransactionSummary)
        } else {
          history.push(dealerActivationPaths.TransactionSummary)
          // history.push(`${pathname.replace(/\/$/, '')}/transaction-summary`)
        }
      } else {
        updatePaymentStore({
          [PAYMENT_STATE.CC_INFO.CC_API_VALIDATION_ERROR]: CARD_VALIDATION_ERRORS.FAILED
        })
      }
    }
  }

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.CURRENT_REGION]: region
  } = useContext(ApplicationContext)

  const location = useLocation()

  const setInitialPaymentState = async () => {
    const paymentConfig = await ContentService.getPaymentConfigCustomer()

    if (paymentConfig?.paymentGateway) {
      updatePaymentStore({ [PAYMENT_STATE.PAYMENT_GATEWAY]: paymentConfig?.paymentGateway })
    }
    if (paymentConfig?.paymentAllowedCountryCodes) {
      updatePaymentStore({
        [PAYMENT_STATE.PAYMENT_ALLOWED_COUNTRY_CODES]: paymentConfig?.paymentAllowedCountryCodes
      })
    }
    if (paymentConfig?.paymentcvvResponseValue) {
      updatePaymentStore({
        [PAYMENT_STATE.PAYMENT_CVV_RESP_VAL]: paymentConfig?.paymentcvvResponseValue
      })
    }
    if (paymentConfig?.paymentAvsPostalResponse) {
      updatePaymentStore({
        [PAYMENT_STATE.PAYMENT_AVS_POSTAL_RESP_VAL]: paymentConfig?.paymentAvsPostalResponse
      })
    }
    updatePaymentStore({[PAYMENT_STATE.SELECTED_PAYMENT_OPTION] : PAYMENT_OPTIONS.CREDIT_CARD})
  }

  useEffect(() => {
    // Set Analytics dataLayer
    setPageAnalytics(location, 'form', region, language)
  }, [language])

  useEffect(() => {
    if (provinceGroups?.length === 0) {
      getCity(provinceId)
        .then((result) => {
          const resultCities = result.map((item) => {
            const { value, id } = item
            const cityName = value.split('-')[1]
            const name =
              cityName && cityName[0]
                ? cityName[0].toUpperCase() + cityName.slice(1).toLowerCase()
                : value
            return {
              value: id,
              name,
              groupName: value
            }
          })
          setAppState({ ...appState, [APPLICATION_STATE.PROVINCE_GROUPS]: resultCities })
        })
        .catch((e) => {
          console.log('error on city fetch', e)
        })
    }
  }, [provinceId, getCity, provinceGroups])

  const updateReferralSetting = async () => {
    const referralSettings = await ContentService.getRAFSettings()
    if (referralSettings) {
      updatePaymentStore({ [PAYMENT_STATE.RAF.FRIEND_REFERRAL_SETTINGS]: referralSettings })
    }
  }

  useEffect(() => {
    if (!friendReferralSettings) {
      updateReferralSetting()
    }
  }, [friendReferralSettings])

  useEffect(() => {
    setInitialPaymentState()
  }, [])

  useEffect(() => {
    if ((appState.is_landing_logic_not_complete === true) || (appState.is_landing_logic_not_complete === 'IN_PROGRESS')) {
      setLandingLogicLoader(false)
    }
  }, [appState.is_landing_logic_not_complete])

  useEffect(() => {
    if (isFirstLoaderComplete) {
      if (site === Sites.consumer) {
        if (isActivateAllProcessing === true) {
          history.push(consumerActivationPaths.ProcessLoader)
        } else {
          history.push(consumerActivationPaths.ProcessFailure)
        }
      } else if (site === Sites.dealer) {
        if (isActivateAllProcessing === true) {
          history.push(dealerActivationPaths.ProcessLoader)
        } else {
          history.push(dealerActivationPaths.ProcessFailure)
        }
      }
    }
  }, [isFirstLoaderComplete])

  const [showErrors, setShowErrors] = useState(false)
  const showAllErrors = () => {
    setButtonIsLoading(false)
    setShowErrors(true)
    updatePaymentStore({ [PAYMENT_STATE.TRIGGER_EPS_CARD_VALIDATION]: true })
  }

  const textContent = useMemo(() => {
    const txtObj = {
      shortTextFields: {},
      longTextFields: {}
    }
    if (!pageContent) return txtObj

    const paymentPage =
      site === Sites.consumer
        ? getById(pageContent, 'paymentPageConsumer')[lang]
        : getById(pageContent, 'paymentPageDealer')[lang]

    const shortTextFields = paymentPage?.shortTextFields
    const longTextFields = paymentPage?.longTextFields
    if (shortTextFields && shortTextFields?.length > 0) {
      shortTextFields?.map((item) => {
        txtObj.shortTextFields[item?.slug] = item?.value
      })
    }
    if (longTextFields && longTextFields?.length > 0) {
      longTextFields?.map((item) => {
        txtObj.longTextFields[item?.slug] = item?.value
      })
    }

    return txtObj
  }, [lang, pageContent])

  const {
    activatingSIMCard,
    step,
    plans,
    title,
    chatBot,
    chatBotUrlLabel,
    chatBotUrl,
    chatBot2,
    moreInfo,
    clickHere,
    moreInfoUrl,
    ok,
    cannotProcessCreditCard,
    createATicket
  } = textContent?.shortTextFields

  const handleSelectNewPhoneNumber = () => {
    dispatch(
      updatePhoneNumberState({
        [PHONE_NUMBER_STATE.PROVINCE_ID]: undefined
      })
    )
    if (userType === Sites.consumer) {
      history.push(consumerActivationPaths.ChooseNumber)
    } else {
      history.push(dealerActivationPaths.ChooseNumber)
    }
  }

  const handleSelectNewPlan = () => {
    if (userType === Sites.consumer) {
      if (preSelectedPlan) {
        history.push(consumerActivationPaths.ChangeNewPlan)
      } else {
        history.push(consumerActivationPaths.Plans)
      }
    } else {
      history.push(dealerActivationPaths.Plans)
    }
  }

  const handleFirstLoaderComplete = () => {
    if (contextState?.payment?.isCompleteEPS === true) {
      setIsFirstLoaderComplete(true)
    }
  }

  return (
    <>
      <div className="space-y-8">
        <div className="flex flex-col max-w-4xl px-4 pb-12 m-auto space-y-8">
          {site === Sites.consumer ? (
            <div className="space-y-8">
              <Heading2 className="pb-4">
                {textContent?.shortTextFields?.paymentHeaderTitle}
              </Heading2>
              <p className="text-base text-copy inline leading-7 font-h4">
                {textContent?.shortTextFields?.paymentHeaderSubTitle}
              </p>
            </div>
          ) : (
            <div className="space-y-8">
              <Heading2 className="pb-4">
                {textContent?.shortTextFields?.paymentHeaderTitle}
              </Heading2>
            </div>
          )}

          <PromoCode {...textContent} onComplete={onComplete} />

          <PaymentOption
            showErrors={showErrors}
            handleSIMActivation={activateSimCard}
            textContent={textContent}
            setButtonIsLoading={setButtonIsLoading}
          />
          <div className="flex justify-between pt-16 items-center">
            <Link
              className="flex items-center justify-start"
              to={
                usePreSelectedPlanOptions
                  ? consumerActivationPaths.ChooseNumber
                  : consumerActivationPaths.Plans
              }
            >
              <img alt="back button" src={content?.chevronBack} />
              <BodyBold className="pl-2 text-h1 underline">
                {usePreSelectedPlanOptions
                  ? `${textContent?.shortTextFields?.previousStepPhoneNumber}`
                  : `${step} 4: ${plans}`}
              </BodyBold>
            </Link>
            <div className="w-1/2">
              <ActivateSIMSection
                showAllErrors={() => showAllErrors()}
                handleSIMActivation={activateSimCard}
                showActivatingPopup={showActivatingPopup}
                setShowActivatingPopup={setShowActivatingPopup}
                setIsProcessingPayment={setIsProcessingPayment}
                isProcessingPayment={isProcessingPayment}
                setIActivateButtonDisabled={setIActivateButtonDisabled}
                paymentGateway={paymentGateway}
                isActivateButtonDisabled={isActivateButtonDisabled}
                setIsActivateAllProcessing={setIsActivateAllProcessing}
                buttonIsLoading={buttonIsLoading}
                setButtonIsLoading={setButtonIsLoading}
              />
            </div>
          </div>
        </div>

        <Popup showPopup={showActivatingPopup}>
          <div className="flex flex-col items-center p-10 rounded-lg">
            <Heading1 className="mb-10">{activatingSIMCard}</Heading1>
            <Loader loading />
          </div>
        </Popup>

        <Popup showPopup={!!errorMessage}>
          {errorMessage && (
            <div className="flex flex-col items-center p-10 rounded-lg">
              <Heading1 className="mb-10">Activating SIM card</Heading1>
              <div className="inline-flex space-x-4">
                <img
                  width="24"
                  height="24"
                  alt="invalid"
                  src={content?.exclamation}
                  className="self-start"
                />
                <div className="space-y-4">
                  <BodyError>{title}</BodyError>
                  <BodyError className="inline-block">
                    {chatBot}
                    {chatBotUrlLabel && (
                      <Link className="font-h1 text-primary" href={chatBotUrl} target="_blank">
                        {chatBotUrlLabel}
                      </Link>
                    )}
                    {chatBot2}
                  </BodyError>
                  <BodyError>
                    {moreInfo}
                    <Link className="font-h1 text-primary" href={moreInfoUrl} target="_blank">
                      {clickHere}
                    </Link>
                  </BodyError>
                  <BodyError testId="transactionSummary-errorCode">{`Error Code: ${errorMessage.code}`}</BodyError>
                  {createATicket && <BodyError>{createATicket}</BodyError>}
                </div>
              </div>
              <Button
                label={ok}
                className="mt-12"
                onClick={() => {
                  setErrorMessage(null)
                }}
              />
            </div>
          )}
        </Popup>
      </div>
      <PlanUnavailablePopup
        province={currProvince}
        onNewPhoneNumberClick={handleSelectNewPhoneNumber}
        onNewPlanClick={handleSelectNewPlan}
        disableClose={true}
      />
    </>
  )
}

export default PaymentPage
